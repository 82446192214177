export default class TemplateSetup {

    constructor() {
        this.bindEvents();
    }

    bindEvents(){
        $('#basic-datatable').on('click', '.js-field-send', this.sendValues);
    }

    onDestroy() {
    }

    sendValues(e) {
        let $self = $(e.currentTarget);
        let $el = $('#'+$self.data('field-id'));
        let item_id = $el.data('id');
        let item_field = $el.data('field');
        let item_object = $el.data('object');

        let data = {}
        data[item_object] = {};
        data[item_object][item_field] = $el.val();


        console.log(data);
        let ajax_data = {
            url: location.protocol + '//' + location.host + location.pathname+"/"+item_id+"/ajax_update",
            data: data,
            method: "put",
            success: function (data){
                application.successNotification(I18n.t("common.field_saved"))
            },
            error: function (err){
                application.errorNotification(I18n.t("common.field_save_failed"))
            }
        }

        $.ajax(ajax_data)
    }
}