import Routes from "./config/Routes";
import ConfigApplication from "../config/Application";
import ConfirmationModal from "./utils/ConfirmationModal";
import TemplateValidation from "./utils/TemplateValidation";
import TemplateSetup from "./utils/TemplateSetup";

export default class Application extends ConfigApplication{
    constructor() {
        super();
        this.routes = new Routes();
        this.confirmationModal = new ConfirmationModal();
        this.datatable = null;
        this.templateValidation = null;
        this.templateSetup = null;
        this.selects = [];
        this.dropifies = [];
        $.ajaxSetup({
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            }
        });

        this.bindEvents()
    }
    pageChanged() {
        super.pageChanged();

        this.onAlertChange();
        this.onNoticeChange();
        this.onErrorChange();
        this.onSuccessChange();

        this.loadLibraries();
        this.setTemplateValidation(window.validationData);
        this.setTemplateSetup();
    }

    bindEvents(){
        super.bindEvents();
        let self = this;
        $(document).off('turbolinks:visit').on('turbolinks:visit', function (){
            if(self.templateValidation !== null){
                self.templateValidation.onDestroy();
                self.templateValidation = null;
            }

            if(self.templateSetup !== null){
                self.templateSetup.onDestroy();
                self.templateSetup = null;
            }

            if(self.selects.length > 0){
                self.selects.forEach(function (item){
                    $(item).selectpicker('destroy');
                })
                self.selects = [];
            }

            if(self.dropifies.length > 0){
                self.dropifies.forEach(function (item){
                    $(item).data('dropify').destroy();
                })
                self.dropifies = [];
            }
        })
    }


    bindEventsAfterPageChanged() {
        super.bindEventsAfterPageChanged();

        $('.js-delete-confirm').unbind('click').click(this.delete_confirm)
    }

    onAlertChange(){
        if(window.warning !== null && window.warning !== undefined && window.warning !== '') {
            this.alertNotification(window.warning);
        }
    }

    onNoticeChange(){
        if(window.notice !== null && window.notice !== undefined && window.notice !== '') {
            this.noticeNotification(window.notice);
        }
    }

    onErrorChange(){
        if(window.error !== null && window.error !== undefined && window.error !== '') {
            this.errorNotification(window.error);
        }
    }

    onSuccessChange(){
        if(window.success !== null && window.success !== undefined && window.success !== '') {
            this.successNotification(window.success);
        }
    }

    successNotification(msg){
        $.NotificationApp.send("Succès!",msg,"top-right","#5ba035","success",5000,4)
    }

    errorNotification(msg){
        $.NotificationApp.send("Erreur!",msg,"top-right","#bf441d","error",5000,4)
    }

    noticeNotification(msg){
        $.NotificationApp.send("Information!",msg,"top-right","#3b98b5","info",5000,4)
    }

    alertNotification(msg){
        $.NotificationApp.send("Atention!",msg,"top-right","#da8609","warning",5000,4)
    }

    delete_confirm() {
        let self = application
        let title = $(this).data("title");
        let message = $(this).data("message");
        let btnPositive = $(this).data("btn-confirm");
        let btnCancel = $(this).data("btn-cancel");
        let url = $(this).data("url");
        let id = $(this).data("id");
        self.confirmationModal.displayConfirm(
            title,
            message,
            {
                positive_btn: {
                    type: "danger",
                    label: btnPositive,
                    callback: function() {
                        let form = document.createElement('form');
                        form.style.visibility = 'hidden';
                        form.action = url;
                        form.method = 'post'

                        let input = document.createElement('input');
                        input.name = '_method';
                        input.value = 'delete';
                        input.type = 'hidden'
                        form.appendChild(input);

                        let inputToken = document.createElement('input');
                        inputToken.name = 'authenticity_token';
                        inputToken.value = $('meta[name="csrf-token"]').attr('content');
                        inputToken.type = 'hidden'
                        form.appendChild(inputToken);

                        document.body.appendChild(form);
                        form.submit();
                    }
                },
                negative_btn: {
                    label: btnCancel
                }
            });
    }

    loadLibraries() {

        let self = this;
        let col_setting = {}

        if(this.datatable !== null){
            this.datatable.destroy();
        }
        if(this.data !== undefined && this.data.has_action !== undefined && this.data.has_action){
            col_setting = { orderable: false, searchable: false, targets: -1 };
        }
        this.datatable = $('.datatable').DataTable({
            language: require('../backoffice/utils/plugins/datatables_translation/'+I18n.locale+'.json'),
            columnDefs: [
                col_setting
            ]
        })

        $('.bootstrap-select').each(function (){
            self.selects.push($(this).selectpicker());
        })

        $('[data-plugin=dropzone]').each(function (){
            self.dropifies.push($(this).dropify({
                messages: {
                    'default': I18n.t('plugins.dropify.messages.default'),
                    'replace': I18n.t('plugins.dropify.messages.replace'),
                    'remove':  I18n.t('plugins.dropify.messages.remove'),
                    'error':   I18n.t('plugins.dropify.messages.error')
                },
                error: {
                    'fileSize': I18n.t('plugins.dropify.messages.fileSize'),
                    'minWidth': I18n.t('plugins.dropify.messages.minWidth'),
                    'maxWidth': I18n.t('plugins.dropify.messages.maxWidth'),
                    'minHeight': I18n.t('plugins.dropify.messages.minHeight'),
                    'maxHeight': I18n.t('plugins.dropify.messages.maxHeight'),
                    'imageFormat': I18n.t('plugins.dropify.messages.imageFormat'),
                    'fileExtension': I18n.t('plugins.dropify.messages.fileExtension')
                }
            }));
        })
    }

    setTemplateValidation(validationData){
        if(validationData !== undefined){
            this.templateValidation = new TemplateValidation(validationData);
        }
    }

    setTemplateSetup() {
        this.templateSetup = new TemplateSetup();
    }
}