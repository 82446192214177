export default class AdminsForm {

    constructor() {
        this.role_select = null;
        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }


    bindEvents() {

        this.role_select = $('#admin_role_id').selectpicker();
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.role_select.selectpicker('destroy');
    }

}